.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  font-weight: bold;
}

.list_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.list_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 20px 0px;
}

.list_item:not(:last-child) {
  border-bottom: 1px solid var(--app-border-grey);
}
