.content_holder {
  height: auto;
  vertical-align: top;
  display: flex;
  flex-direction: row;
}

.detail_group {
  flex: 0.35;
}

.table_group {
  flex: 0.65;
  padding: 0px 0px 0px 10px;
}

.title_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  padding: 5px;
  line-height: 30px;
}

.title_label {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
}

.table_container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
}

.flex_direction_row {
  display: "flex" !important;
  flex-direction: "row";
}

.show_hide_button {
  margin-left: 1.2em;
  font-size: 18px;
  color: var(--app-blue);
  cursor: pointer;
}

.show_hide_arrow {
  color: var(--app-blue);
  margin-bottom: 0.2em;
}

.backdrop {
  opacity: 0.5 !important;
}

.dropdown_style {
  width: 100%;
  margin-bottom: 1em;
  height: 2.5em;
}

.dropdown_disabled_style {
  width: 100%;
  margin-bottom: 1em;
  height: 2.5em;
  opacity: 0.3;
}

.commentBoxContainer {
  margin-top: 1em;
}

.comment_box_input {
  min-height: 100px;
  height: auto;
  width: 100%;
  font-size: 16px;
  text-align: left;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  resize: none;
  background: white;
  flex: 1;
  overflow: hidden;
  padding: 0.5em;
}

.initials_input {
  height: auto;
  width: 100%;
  font-size: 16px;
  text-align: left;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  resize: none;
  background: white;
  flex: 1;
  overflow: hidden;
  padding: 0.5em;
}

.button {
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  margin-right: 4%;
  width: 10em;
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.9em;
}

.cancel_button {
  background-color: #eaeaea;
  color: var(--text-primary);
}
.cancel_button:active,
.cancel_button:hover {
  filter: brightness(75%);
}

.save_button {
  background-color: var(--app-blue);
  color: white;
}

.danger_button {
  background-color: #f62227;
  color: white;
}

.danger_button:active,
.danger_button:hover,
.save_button:active,
.save_button:hover {
  filter: brightness(150%);
}

.danger_button:disabled,
.save_button:disabled {
  opacity: 0.3;
}

.modal_header {
  padding: 2rem 2rem 1rem 2rem;
}

.modal_title {
  font-weight: 600;
}

.modal_body {
  padding: 1.5rem;
}

.modal_footer {
  padding: 1.5rem;
}

.modal_input_label {
  color: var(--text-primary);
}

.modal_select_component:disabled {
  opacity: 0.5;
}

.add_location_btn {
  font-size: 16px;
  color: var(--app-blue);
  cursor: pointer;
}
