.status_modal {
  background-color: var(--status-green);
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  padding: 6px 18px;
  width: fit-content;
  margin: auto;
  color: white;
}

.error {
  background-color: var(--status-red);
}

.message_text {
  margin-top: 1px;
  margin-left: 10px;
  font-family: var(--font);
  font-weight: 500;
}
