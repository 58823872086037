.history_table_container {
  padding: 20px;
}

.table_container {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.header_container {
  background-color: var(--app-blue);
  display: flex;
  flex-direction: row;
  color: var(--app-white);
  padding: 5px 5px 5px 10px;
  font-size: 0.9em;
  font-weight: 600;
}

.header_style {
  width: 100%;
}

.table_title {
  font-size: 24px;
  font-family: var(--font);
  font-weight: 600;
  margin-bottom: 10px;
}

.event_row {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  background-color: #ffffff;
  color: #4c4c4c;
  border: 1pt solid #f6f6f6;
  padding: 5px 5px 5px 10px;
}

.event_row_text {
  width: 100%;
  flex-direction: column;
}
