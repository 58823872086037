.grid_layout {
  border: 2px solid transparent;
}

.grid_layout_editable {
  border-radius: 5px;
  border-color: var(--app-border-grey);
}

.grid_item_container {
  background-color: var(--app-white);
  border: 2px solid var(--app-border-grey);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex: 1;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}

.grid_item_container_editable {
  border-style: dashed;
  cursor: grab;
}

.grid_item_container_editable:hover {
  border-width: 3px;

  border-color: var(--app-blue);
}

.grid_item_container_editable:active {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

.grid_item_remove_button {
  background-color: white;
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.grid_item_container_editable:hover > .grid_item_remove_button {
  right: -11px;
  top: -11px;
}

.grid_item_remove_icon {
  border-radius: 100%;
  opacity: 0.6;
}

.grid_item_remove_icon:hover {
  opacity: 1;
}

.grid_item_widget_container {
  overflow: hidden;
  flex: 1;
  display: flex;
}
