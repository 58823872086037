.onboard_modal_container {
  position: absolute;
  top: 0px;
  right: 0;
  transform: none !important;
  max-height: none;
  height: 100%;
  margin: 0;
  max-width: none !important;
}
