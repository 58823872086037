.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.information_collapsible_button {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: calc(650px - 15px);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: var(--app-blue);
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
  z-index: 2;
  transition: 500ms ease-in-out;
  cursor: pointer;
}

.information_collapsible_button_collapsed {
  right: 5px;
  transition: 500ms ease-in-out;
}

.information_container {
  background-color: white;
  position: absolute;
  right: 0px;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  max-width: 650px;
  width: 650px;
  transition: width 500ms ease-in-out;
  z-index: 1;
  border-left: 1px solid var(--app-border-grey);
}

.information_container.information_container_collapsed {
  width: 0%;
  animation: fadeOut 500ms;
  animation-fill-mode: both;
}
