.inv_modal_container {
  position: absolute;
  top: 0px;
  right: 0;
  transform: none !important;
  max-height: none;
  min-width: 800px;
  height: 100%;
  margin: 0;
  max-width: none !important;
}

.add-inv-body {
  padding: 0px;
  width: 100%;
}

.modal_body {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-inv-title {
  width: 100%;
}

.add-inv-header {
  margin: 0px !important;
  padding: 0px;
}

.add-inv-footer {
  border-top: 1.62px solid var(--app-border-grey);
  height: 61px;
  background: var(--app-white);
  box-shadow: 0px -1px 8px -5px rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 0px 6px;
}

.loading_message {
  font-style: italic;
}

.single_create_tab {
  display: flex;
  flex-direction: column;
}
