:root {
  --font-size-xsmall: 12px;
  --font-size-small: 15px;
  --font-size-base: 17px;
  --font-size-large: 20px;
  --font-size-xlarge: 25px;
  --app-blue: #4a90ff;
  --app-lightblue: #e9f3fb;
  --app-border-grey: #e2e2ea;
  --app-offwhite: #f1f1f2;
  --app-background: #fbfafa;
  --app-white: #ffffff;
  --app-lightblack: #1b1c1e;
  --app-lightgrey: #b2b2ba;
  --app-purple: #62769e;
  --app-lightpurple: #e0e4ec;
  --app-sidenav-icon-dark: #343434;
  --asset-primary: #f66469;
  --asset-secondary: #fef0f1;
  --inventory-primary: #297ad9;
  --inventory-secondary: #d5e5f7;
  --shipment-primary: #ffbc00;
  --shipment-secondary: #fef3d2;
  --shipment-text: #c99001;
  --order-primary: #008e73;
  --order-secondary: #d6eae6;
  --purchase-primary: #8a4ee3;
  --purchase-secondary: #e2def8;
  --package-primary: #ff9147;
  --package-secondary: #ffe7d7;
  --neutral-primary: #737880;
  --neutral-secondary: #ebebed;
  --status-green: #4b9910;
  --status-lightgreen: #edffef;
  --status-yellow: #f6b044;
  --status-orange: #e56201;
  --status-orange-light: #fddfc8;
  --status-red: #ff3d46;
  --text-primary: #343434;
  --text-secondary: #979799;
  --xemelgo-blue: #0D8CFF;
  --xemelgo-light-blue: #EDF5FF; 
  --font: "Nunito";
  --font-bold: "Nunito-Bold";
  --font-bold-italic: "Nunito-BoldItalic";
  --font-italic: "Nunito-Italic";
  --font-regular: "Nunito-Regular";
  --font-semibold: "Nunito-SemiBold";
}