.layout_list_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.layout_button {
  border: 2px solid var(--app-border-grey);
  background-color: var(--app-white);
  border-radius: 5px;
  padding: 5px 15px;
  font-weight: 500;
}

.layout_button_active {
  color: var(--app-blue);
  background-color: var(--app-lightblue);
  border-color: var(--app-blue);
}

.layout_button:hover,
.layout_button:active {
  border-color: var(--app-blue);
}
