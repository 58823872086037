.table {
  width: 100%;
}

.th {
  background-color: var(--xemelgo-blue);
  color: white;
  font-size: var(--font-size-xsmall);
  /* font-family: var(--font-bold); */
  font-weight: bold !important;
  padding: 6px;
}

.tr {
  font-size: var(--font-size-xsmall);
  /* font-family: var(--font-regular); */
  color: var(--text-primary);
  padding: 6px;
  border: 1px solid var(--app-border-grey);
}

.td {
  padding: 6px;
}

.tr:nth-child(even) {
  background-color: var(--app-offwhite);
}

.tr:nth-child(odd) {
  background-color: white;
}

/* table th,
td {
  border-left: 1px solid var(--app-border-grey);
} */
