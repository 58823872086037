.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.chatbot_container {
  z-index: 2;
  position: fixed;
  top: 69px;
  right: 0px;
  height: 100%;
}

.fullscreen_chatbot_container {
  height: 100%;
}

/* the chatbot component has some divs w/o styling... should probably re-style inner components instead */
.fullscreen_chatbot_container > div > div:first-child {
  height: 95vh !important;
}

.information_collapsible_button {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 40%;
  right: calc(425px - 15px);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: var(--app-blue);
  background-color: white;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
  border: .5px solid var(--app-border-grey);
  z-index: 3;
  transition: 500ms ease-in-out;
  cursor: pointer;
}

.information_collapsible_button_collapsed {
  /* top: 82%; */
  right: 0px !important;
  width: 50px;
  height: 100px;
  border-radius: 6px;
  border: 1px solid var(--app-border-grey);
  transition: 500ms ease-in-out;
  color: var(--app-blue);
  font-weight: bold;
  -webkit-clip-path: inset(-5px 9px -5px -5px);
  clip-path: inset(-5px 9px -5px -5px);
}

.expand_chatbot_tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 90%;
  justify-content: space-evenly;
}

.collapsed_tab_bar {
  height: 100%;
  background-color: white;
  width: 10px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
  right: 14px !important;
  position: relative;
  transition: 700ms ease-in-out;
  -webkit-clip-path: inset(0px 0px 0px -400px);
  clip-path: inset(0px 0px 0px -400px);
  border-right: 1px solid var(--app-border-grey);

}

.information_container {
  position: absolute;
  right: 0px;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  max-width: 425px;
  width: 425px;
  border-top-left-radius: 10px;
  transition: width 500ms ease-in-out;
  z-index: 2;
  border-left: 1px solid var(--app-border-grey);
}

.fullscreen_information_container {
  position: absolute;
  right: 0px;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  border-top-left-radius: 10px;
  transition: width 500ms ease-in-out;
  z-index: 2;
  border-left: 1px solid var(--app-border-grey);
}

.fullscreen_information_container {
  width: 100%;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  border-top-left-radius: 10px;
  z-index: 2;
  border-left: 1px solid var(--app-border-grey);
}

/* .information_container > div:first-child {
  flex: 0.925;
} */

.information_container.information_container_collapsed {
  width: 0%;
  animation: fadeOut 500ms;
  animation-fill-mode: both;
}

.chatbot_footer {
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom: 1px solid var(--app-border-grey);
}

.powered_by_text {
  font-size: var(--font-size-small);
  color: var(--text-primary);
}
