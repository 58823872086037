.link {
  width: 80%;
  justify-content: center;
  display: flex;
  border: 2px solid var(--app-blue);
  padding: 10px 0px;
  margin: 10px 0px;
  border-radius: 5px;
  color: var(--app-blue);
  font-weight: 600;
  align-items: center;
}

.link:hover {
  color: var(--app-blue);
  background-color: var(--app-lightblue);
}
