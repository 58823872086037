.tracking_departments_links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 10px;
  flex-grow: 1;
  box-shadow: 0 2px 5px 0 var(--app-border-grey);
}

.tracking_links_header {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  background-color: var(--app-blue);
  color: var(--app-white);
  padding: 5px 5px 5px 10px;
  font-size: 0.9em;
  font-weight: 600;
  border-radius: 3px 3px 0px 0px;
}

.tracking_departments_links_detail {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  padding-left: 8px;
  padding-right: 8px;
}

.tracking_department_link_row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: var(--app-white);
  color: var(--text-primary);
  border-bottom: 1pt solid var(--app-border-grey);
  padding-top: 5px;
  padding-bottom: 5px;
}

.tracking_department_link_row_empty {
  color: var(--text-primary);
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.time_duration {
  font-size: 0.9em;
  flex: 0.25;
  text-align: left;
  align-self: center;
  padding-left: 0.15em;
}

.time_detail {
  text-align: left;
  flex: 0.25;
}

.show_button {
  text-transform: capitalize;
  text-decoration: underline;
  color: var(--app-blue);
  cursor: pointer;
  font-weight: 500;
}

.time_tracking_table {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

.time_tracking_header_row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: var(--app-white);
  color: var(--text-primary);
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
}
.time_tracking_data_row {
  margin: 1px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: var(--app-white);
  color: var(--text-primary);
  padding: 5px;
  font-size: 14px;
}
.time_tracking_status {
  display: flex;
  width: 75px;
  justify-content: center;
  align-items: content;
  text-transform: capitalize;
  color: var(--app-white);
  font-size: 12px;
  border-radius: 20px;
}

.status {
  color: "white" !important;
  width: "fit-content" !important;
  padding-left: 0.4em;
  padding-right: 0.4em;
  border-radius: 1em;
  margin-bottom: 0.2em;
}

.clickable_text {
  color: var(--app-blue);
}