.close_button {
  float: right;
  padding-right: 1%;
}
.close_icon {
  cursor: pointer;
  stroke: var(--app-blue);
  stroke-width: 1.9833px;
  justify-content: flex-end;
}

.title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 10px 10px 30px;
  border-bottom: 1.6px solid var(--app-border-grey);
}

.inv_title_container {
  padding-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.header_title_icon_container {
  border: 4.5px solid var(--inventory-secondary);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid --app-lightblue;
  border-radius: 40px;
  padding: 2px;
  border-opacity: 0.4;
  margin-right: 5%;
}

.header_title {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0.0427865px;
  color: var(--text-primary);
  width: 25%;
}

.header_additional_message {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0337788px;
  margin-top: 3px;
  white-space: pre-line;
}

.success {
  color: var(--status-green);
}

.error {
  color: var(--status-red);
}

.item_information_container {
  height: 333px;
  background-color: #fbfafa;
  border-top: 1.62px solid var(--app-border-grey);
  padding: 18px 0px 35px 31px;
}

.info_content_title {
  display: flex;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0.0427865px;
  padding-bottom: 20px;
}

.location_selector_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.info_content_label {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.410612px;
  padding-bottom: 5px;
}

.input_container {
  width: 235px;
}

.text_input_container {
  padding-bottom: 25px;
}

.dropdown_input_container {
  padding-bottom: 15px;
}

.item_information_card_container {
  padding-top: 23px;
}

.item_information_card {
  display: flex;
}

.item_type_image {
  margin-right: 64px;
}

.item_type_image {
  width: 145px;
  height: 145px;
  border: 1px solid var(--app-border-grey);
  box-sizing: border-box;
  border-radius: 3px;
}

.additional_information_container {
  height: 57%;
  border-top: 1.62px solid var(--app-border-grey);
  padding: 17px 0px 0px 31px;
}

.info_card_row {
  display: flex;
  padding-bottom: 20px;
}

.info_card_row_label_container {
  width: 100px;
}

.info_card_row_value_container {
  display: flex;
  width: 120px;
}

.info_card_row_label {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0337788px;
  color: var(--text-primary);
}

.info_card_row_value {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0337788px;
  color: var(--text-primary);
}

.status_icon {
  border-radius: 5px;
  width: 10px;
  height: 10px;
  align-self: center;
  margin-right: 10px;
}

.green {
  background-color: #6cb444;
}

.red {
  background-color: var(--status-red);
}

.main_container {
  display: flex;
  height: 300px;
}

.left_container {
  width: 140px;
  border-right: 0.1px solid #c6cbd4;
}

.right_container {
  width: 402px;
}

.tab_list_style {
  overflow-y: auto;
  max-height: 600px;
}

.tab_style {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0315269px;
  color: var(--text-primary);
  text-align: left;
  background-color: transparent;
  text-transform: none;
  padding: 2px 0px 0px 10px;
  overflow-wrap: anywhere;
  border: none;
  cursor: pointer;
  color: Black;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center !important;
  border-radius: 3px;
  width: 126px;
  height: 33px;
}

.tab_style:focus {
  outline: none;
}

.tab_style:hover {
  color: black;
}

.focused_tab_style {
  background: #258fe0;
  color: white;
}

.tab_style.focused_tab_style {
  color: white;
}

.tab_style.focused_tab_style:hover {
  color: black;
  background-color: #efefef;
}

.add_content {
  display: flex;
}

.scan_button {
  height: 36px;
  width: 100px;
  background-color: #258fe0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  margin: 2px 17px;
  border-radius: 3px;
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.410612px;
  color: var(--app-white);
}

.scan_button[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.footer_container {
  display: flex;
  justify-content: flex-end;
}

.button_container {
  display: flex;
}

.button_container > button {
  border: none;
}

.button_container > button:disabled {
  opacity: 0.2;
}

.button_container > button:focus {
  border: solid;
  border-color: var(--text-primary);
}

.button_container > button:hover {
  opacity: 0.7;
}

.blue_button {
  height: 36px;
  width: 106px;
  background-color: var(--app-blue);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  margin: 0 5%;
  border-radius: 3px;
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.410612px;
  color: var(--app-white);
}

.blue_button[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.gray_button {
  height: 36px;
  width: 106px;
  background-color: var(--app-offwhite);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  margin: 0 1%;
  border-radius: 3px;
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.410612px;
  color: var(--text-primary);
}

.dropdown_no_result_message {
  text-align: center;
  color: var(--app-lightgrey);
  padding: 1rem;
  font-weight: 400;
  font-size: 15px;
  margin: auto;
}

.dropdown_item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-weight: 400;
}

.dropdown_item:hover {
  background-color: var(--app-offwhite);
}

.dropdown_label_container {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 100%;
  overflow-x: hidden;
}

.asterisk {
  color: var(--status-red) !important;
}
