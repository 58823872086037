.summary_card {
  background-color: #0D8CAA;
  color: white;
  text-align: left;
  border-radius: 5px;
}

.label_text {
  font-size: .9rem;
  margin-left: 5px;
  margin-top: 3px;
  padding-bottom: 0px;
  font-weight: bold;
}

.value_text {
  font-size: .7rem;
  margin-left: 10px;
  margin-top: 3px;
  padding-bottom: 5px;
  font-weight: bold;
}