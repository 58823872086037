.modal_title {
  margin-top: 1rem;
  margin-left: 1.5rem;
  font-size: 26px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0.06px;
  text-align: left;
}

.modal_body {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.045px;
  text-align: left;
}
