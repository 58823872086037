.react_journey__progress-graph {
  width: 90%;
  margin: 0.5em 1.5em 1.5em -3em;
  position: relative;
  height: 100%;
}

.react_journey__container { 
  margin: 0.5em;
  padding: 5px;
}
.react_journey__label {
  font-weight: bold;
  font-size: 14;
  color: var(--text-primary);
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom__button {
  /* Green */
  border: none;
  color: white;
  margin: 10px;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 10px;
  font-weight: 500;
}

.custom__green {
  background-color: rgb(34, 139, 37);
}

.custom__red {
  background-color: rgb(172, 27, 27);
}
