.printer_information_container {
  padding: 10px;
}

.printer_selection_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.dropdown_input_container {
  margin: 10px 0px;
}

.header {
  margin-bottom: 20px;
  font-weight: bold;
}

.sub_header {
  font-weight: bold;
}

.asterisk {
  color: var(--status-red) !important;
}
