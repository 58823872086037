.initial_message_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  padding: 0px 12px 0px 12px;
}

.option {
  width: 100%;
  padding: 0.5rem;
  border-radius: 6px;
  background: transparent;
  border: 1px solid var(--app-border-grey);
  background-color: white;
  padding: 14px;
  text-align: left;
  margin-top: 3px;
  margin-bottom: 3px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex: 1;
}

.option_text {
  flex: 0.9;
  color: var(--text-primary);
  font-size: var(--font-size-small);
  /* font-family: var(--font-regular); */
}

.image_container {
  margin-bottom: 25px;
  max-width: 250px;
}

.initial_message_text {
  text-align: center;
  color: var(--text-primary);
  font-size: var(--font-size-base) !important;
  /* font-family: var(--font-bold) !important; */
  font-weight: bold !important;
  margin-bottom: 44px;
}

.options_header_text {
  text-align: left;
  color: var(--text-primary);
  font-size: var(--font-size-small) !important;
  /* font-family: var(--font-bold) !important; */
  font-weight: bold !important;
  margin-bottom: 14px;
}
