.container {
  position: relative;
  letter-spacing: 0.5px;
}

.filter_button {
  position: relative;
  background-color: var(--app-white);
  border-radius: 5px;
  color: var(--app-blue);
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 8px 10px;
}

.filter_button:hover {
  background-color: #f7f7f7;
}

.filter_button_text {
  font-size: 14px;
  font-weight: 500;
}

.filter_menu {
  position: absolute;
  margin-top: 5px;
  border: 1px solid var(--app-border-grey);
  border-radius: 5px;
  background-color: var(--app-white);
  right: 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-direction: column;
  min-height: calc(80vh - 280px);
  max-height: calc(100vh - 280px);
  width: 350px;
}

.menu_content {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
}

.menu_header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.menu_title_container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}

.menu_title_text {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-primary);
}

.menu_title_subtext {
  font-size: 14px;
  color: var(--text-secondary);
}

.clear_all_text_button {
  color: var(--app-blue);
  font-size: 14px;
}

.clear_all_text_button:hover {
  opacity: 0.6;
}

.menu_buttons_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid var(--app-border-grey);
  gap: 20px;
}

.button_base {
  padding: 6px 25px;
  border-radius: 3px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.button_base:hover {
  opacity: 0.8;
}

.cancel_button {
  background-color: var(--app-offwhite);
  color: var(--text-primary);
}

.apply_button {
  background-color: var(--app-blue);
  color: var(--app-white);
}
