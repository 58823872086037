.list_container {
  overflow-y: auto;
  flex: 1;
}

.list_item_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--app-border-grey);
  padding: 20px 10px;
  justify-content: space-between;
  gap: 30px;
}

.view_button {
  padding: 0px;
  margin: 0px;
  font-weight: 500;
  color: var(--app-blue);
}

.view_button:hover {
  opacity: 0.6;
}

.list_item_text {
}

.list_item_text_clickable {
  font-weight: 500;
  color: var(--app-blue);
  cursor: pointer;
  text-decoration: underline;
}

.list_item_text_clickable:hover {
  opacity: 0.6;
}
