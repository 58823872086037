.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.table_pager {
  align-self: flex-end;
  justify-content: center;
  align-items: center;
}

.table_pager_button {
  padding: 3px 0px;
  color: var(--app-blue);
  border: 1px solid var(--app-border-grey);
  border-radius: 5px;
  cursor: pointer;
}

.table_pager_button:hover {
  border-color: var(--app-blue);
  color: var(--app-blue);
}

.table_pager_button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #ccc;
  pointer-events: none;
}

.table_pager_button.disabled:hover {
  border-color: var(--app-border-grey);
  color: #ccc;
}

.disabledButton {
  color: var(--text-secondary);
}

.table_pager_number {
  margin: 0px 20px;
  letter-spacing: 0.5px;
}

.table_pager_size_text {
  margin: 0px 15px;
}

.table_pager_size_selector {
  background: transparent;
  background-image: none; /* set to an image */
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  padding: 0.25em 0.25em;
  cursor: pointer;
  border: 0px solid white;
  outline: 0px white;
  margin-right: 40px;
}

.table_pager_size_dropdown {
  color: var(--app-blue);
}

.table_pager_size_item {
  color: var(--text-primary);
}

.filter_bar_container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.filter_bar_icon {
  position: absolute;
  font-size: 1rem;
  margin-left: 10px;
  color: var(--neutral-primary);
  padding-bottom: 3px;
}

.filter_bar {
  height: 40px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  font-size: 1rem;
  color: var(--neutral-primary);
  padding-left: 2em;
  text-overflow: ellipsis;
  /* Text */
  font-family: "Avenir", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--text-secondary);
}

.dropdown_button {
  padding-left: 0.5em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: var(--app-border-grey);
  cursor: pointer;
  height: 100%;
  font-weight: bold;
}

.dropdown_container {
  position: relative;
}

.dropdown_menu_container {
  position: absolute;
  background-color: transparent;
  padding-bottom: 30px;
  width: 100%;
  z-index: 2147483647;
}

.dropdown_menu {
  margin: 0px;
  background-color: white;
  margin-top: 5px;
  padding: 0px;
  border: 1px solid var(--app-border-grey);
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 100px;
}

.dropdown_item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-weight: 400;
  word-break: break-all;
}

.dropdown_item:hover {
  background-color: var(--app-offwhite);
}

.dropdown_no_result_message {
  text-align: center;
  color: var(--app-lightgrey);
  padding: 1rem;
  font-weight: 400;
  word-break: break-all;
  margin: auto;
}

.empty_list_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: var(--app-lightgrey);
  font-weight: 500;
  font-size: 18px;
  height: 100%;
}

.item_container {
  /* overflow-y: auto; */
}

.list_table {
  height: 100%;
}

.export_csv_button {
  width: 130px;
  border-radius: 5px;
  font-weight: bold;
  background-color: var(--app-blue);
  color: white;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dropdown_button:hover,
.export_csv_button:hover {
  opacity: 0.8;
}
