.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tags_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: var(--app-lightblue);
  padding: 5px 15px;
  border-radius: 20px;
  align-items: center;
}

.tag_text {
  font-weight: 500;
  color: var(--text-primary);
}

.remove_button {
  cursor: pointer;
  color: var(--app-blue);
}

.remove_button:hover {
  color: var(--text-primary);
}
